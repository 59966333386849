export const analyticsEndpoints = (builder) => {
  return {
    getOrdersData: builder.mutation({
      query: (ts) => ({
        url: `/analytics/orders?timestamp=${ts}`,
        method: 'get',
      }),
    }),
    getLorasData: builder.mutation({
      query: (ts) => ({
        url: `/analytics/topLoras?timestamp=${ts}`,
        method: 'get',
      }),
    }),
    getSubscriptionUsage: builder.mutation({
      query: () => ({
        url: `/analytics/usage`,
        method: 'get',
      }),
    }),
    getRevenuecat: builder.mutation({
      query: () => ({
        url: `/analytics/revenuecat`,
        method: 'get',
      }),
    }),
  };
};
