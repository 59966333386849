import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ButtonGroup, Divider } from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import { AddAPhoto, Delete, VideoCameraFront } from '@mui/icons-material';

import {
  useGetProductMutation,
  useSetProductVideosMutation,
  useDeleteProductVideoMutation,
} from 'api/apiSlice';

export default function ProductVideo({ name, id }) {
  const [open, setOpen] = useState(false);
  const [files, setfiles] = useState([]);
  const [images, setImages] = useState([]);
  const [thumb, setthumb] = useState('');

  const [thumbs, setthumbs] = useState([]);
  const [featureds, setfeatureds] = useState([]);
  const [hthumbs, seththumbs] = useState([]);
  const [horizontals, sethorizontals] = useState([]);

  const [loading, setloading] = useState(false);
  const [currenttab, setcurrenttab] = useState(0);

  const [getProductVideos, { isloading }] = useGetProductMutation();
  const [setProductVideos] = useSetProductVideosMutation();

  // load images from backend
  useEffect(() => {
    if (open) {
      setImages([]);
      setloading(true);

      getProductVideos(id)
        .unwrap()
        .then((resp) => {
          try {
            console.log(resp);
            const _featureds = resp.videos.featured.map((video, index) => {
              var species = video.split('.')[0].split('_')[1];
              var gender = video.split('.')[0].split('_')[2];
              var item = {
                img: `https://devrest.garagefarm.net//products/product/${name.replace(
                  ' ',
                  ''
                )}/video?type=vertical&species=${species}_${gender}`, //sample.img,
                species: species,
                gender: gender,
                thumb: false,
              };
              return item;
            });
            console.log(_featureds);
            const _horizontals = resp.videos.horizontal.map((video, index) => {
              var species = video.split('.')[0].split('_')[2];
              var gender = video.split('.')[0].split('_')[3];
              var item = {
                img: `https://devrest.garagefarm.net//products/product/${name.replace(
                  ' ',
                  ''
                )}/video?type=horizontal&species=${species}_${gender}`, //sample.img,
                species: species,
                gender: gender,
                thumb: false,
              };
              return item;
            });
            console.log(_horizontals);
            const _thumbs = resp.videos.featured.map((video) => {
              var species = video.split('.')[0].split('_')[1];
              var gender = video.split('.')[0].split('_')[2];
              var item = {
                img: `https://devrest.garagefarm.net//products/product/${name.replace(
                  ' ',
                  ''
                )}/video/thumb?type=vertical&species=${species}_${gender}`, //sample.img,
                species: species,
                gender: gender,
                thumb: true,
              };
              return item;
            });
            const _hthumbs = resp.videos.featured.map((video) => {
              var species = video.split('.')[0].split('_')[1];
              var gender = video.split('.')[0].split('_')[2];
              var item = {
                img: `https://devrest.garagefarm.net//products/product/${name.replace(
                  ' ',
                  ''
                )}/video/thumb?type=horizontal&species=${species}_${gender}`, //sample.img,
                species: species,
                gender: gender,
                thumb: true,
              };
              return item;
            });
            // if (imgs.data.images[0].images.thumb.length !== 0) {
            //   const thumb = { ...imgs.data.images[0].images.thumb[0] };
            //   setImages([thumb, ...samples]);
            // } else {
            //   setImages([...samples]);
            // }

            setthumbs(_thumbs);
            setfeatureds(_featureds);
            seththumbs(_hthumbs);
            sethorizontals(_horizontals);

            setloading(false);
          } catch (e) {
            setloading(false);
            console.log('error obtaining product images', e);
          }
        });
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    // const samples = images.filter((img) => {
    //   return img.thumb === false;
    // });
    // const thumb = images.filter((img) => {
    //   return img.thumb === true;
    // });

    const formData = new FormData();
    formData.append('name', name);

    var t_data = [];
    var f_data = [];
    var ht_data = [];
    var h_data = [];
    var files_count = 0;
    thumbs.forEach((item, index) => {
      if (item.hasOwnProperty('file')) {
        formData.append('thumbs', item.file);
        t_data.push({
          filename: `${name.replace(' ', '')}_${item.species}_${item.gender}`,
          gender: item.gender,
          species: item.species,
        });
        files_count += 1;
      }
    });
    featureds.forEach((item, index) => {
      if (item.hasOwnProperty('file')) {
        formData.append('featureds', item.file);
        f_data.push({
          filename: `${name.replace(' ', '')}_${item.species}_${item.gender}`,
          gender: item.gender,
          species: item.species,
        });
        files_count += 1;
      }
    });
    hthumbs.forEach((item, index) => {
      if (item.hasOwnProperty('file')) {
        formData.append('hthumbs', item.file);
        ht_data.push({
          filename: `horizontal_${name.replace(' ', '')}_${item.species}_${
            item.gender
          }`,
          gender: item.gender,
          species: item.species,
        });
        files_count += 1;
      }
    });
    horizontals.forEach((item, index) => {
      if (item.hasOwnProperty('file')) {
        formData.append('horizontals', item.file);
        h_data.push({
          filename: `horizontal_${name.replace(' ', '')}_${item.species}_${
            item.gender
          }`,
          gender: item.gender,
          species: item.species,
        });
        files_count += 1;
      }
    });

    formData.append('t_data', JSON.stringify(t_data));
    formData.append('f_data', JSON.stringify(f_data));
    formData.append('ht_data', JSON.stringify(ht_data));
    formData.append('h_data', JSON.stringify(h_data));

    if (files_count > 0) {
      setloading(true);
      setProductVideos(formData)
        .unwrap()
        .then((resp) => {
          setloading(false);
          console.log('sent');
        });
    }
  };

  const handleTabChange = (event, newValue) => {
    setcurrenttab(newValue);
  };

  const tabs = [
    { value: 0, name: 'human male', species: 'human', gender: 'male' },
    { value: 1, name: 'human female', species: 'human', gender: 'female' },
    { value: 2, name: 'cat male', species: 'cat', gender: 'male' },
    { value: 3, name: 'cat female', species: 'cat', gender: 'female' },
    { value: 4, name: 'dog male', species: 'dog', gender: 'male' },
    { value: 5, name: 'dog female', species: 'dog', gender: 'female' },
  ];

  return (
    <>
      <div onClick={handleClickOpen}>
        <VideoCameraFront />
        Add Videos
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle></DialogTitle>
        <DialogContent sx={{ height: '100vh' }}>
          <Tabs
            value={currenttab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            {tabs.map((tab) => {
              return <Tab label={tab.name} value={tab.value} />;
            })}
          </Tabs>
          {tabs.map((tab, index) => {
            return (
              <SingleTab
                gender={tab.gender}
                species={tab.species}
                loading={loading}
                setloading={setloading}
                thumbs={thumbs}
                setthumbs={setthumbs}
                featureds={featureds}
                setfeatureds={setfeatureds}
                hthumbs={hthumbs}
                seththumbs={seththumbs}
                horizontals={horizontals}
                sethorizontals={sethorizontals}
                value={currenttab}
                index={index}
                name={name}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            variant="filled"
            onClick={handleClose}
            sx={{ margin: '10px' }}
          >
            CANCEL
          </Button>
          <Button variant="filled" onClick={handleSave} sx={{ margin: '10px' }}>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function SingleTab({
  gender,
  species,
  loading,
  setloading,
  thumbs,
  setthumbs,
  horizontals,
  sethorizontals,
  featureds,
  setfeatureds,
  hthumbs,
  seththumbs,
  value,
  index,
  name,
}) {
  const inputRefs = useRef({});
  const handleUpload = (type, files) => {
    const f = [...files];
    const types = {
      thumb: setthumbs,
      featured: setfeatureds,
      horizontal: sethorizontals,
      hthumb: seththumbs,
    };
    setloading(true);
    f.map((file) => {
      const reader = new FileReader();
      var item = {
        img: '',
        file: file,
        species: species,
        gender: gender,
        thumb: false,
      };
      reader.onload = (e) => {
        item.img = e.target.result;
        // setImages((current) => [...current, item]);
        types[type]((current) => [
          ...current.filter(
            (item) => item.species !== species && item.gender !== gender
          ),
          item,
        ]);
      };
      reader.readAsDataURL(file);

      return true;
    });
    setloading(false);
  };

  const handleDelete = () => {};
  return (
    <>
      {value === index && (
        <Box
          noValidate
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: 'fit-content',
            paddingTop: '40px',
            height: '300px',
            gap: '50px',
          }}
        >
          <Box
            sx={{
              mt: 1,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'space-between',
              gap: '50px',
            }}
          >
            <Box
              sx={{
                mt: 1,
                width: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <label for="thumb">thumb</label>
              <img
                src={
                  thumbs.filter(
                    (item) => item.species === species && item.gender === gender
                  )[0]?.img
                }
                alt=""
                height="300px"
                style={{ objectFit: 'scale-down' }}
              />
              <Box display="flex" flexDirection="row" gap="10px">
                <Button
                  variant="contained"
                  onClick={() =>
                    inputRefs.current[`thumb_${species}_${gender}`]?.click()
                  }
                >
                  upload
                </Button>
                {/* <Button variant="contained" onClick={handleDelete}>
                  delete
                </Button> */}
              </Box>
              <input
                ref={(el) =>
                  (inputRefs.current[`thumb_${species}_${gender}`] = el)
                }
                id="thumb"
                type="file"
                onChange={(e) => handleUpload('thumb', e.target.files)}
                accept="image/webp"
                style={{ display: 'none' }}
              />
            </Box>
            <Box
              sx={{
                mt: 1,
                width: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <label for="featured">featured video</label>
              <VideoPlayer
                src={
                  featureds.filter(
                    (item) => item.species === species && item.gender === gender
                  )[0]?.img
                }
              />
              <Box display="flex" flexDirection="row" gap="10px">
                <Button
                  variant="contained"
                  onClick={() =>
                    inputRefs.current[`video_${species}_${gender}`]?.click()
                  }
                >
                  upload
                </Button>
                {/* <Button variant="contained" onClick={handleDelete}>
                  delete
                </Button> */}
              </Box>

              <input
                ref={(el) =>
                  (inputRefs.current[`video_${species}_${gender}`] = el)
                }
                id="featured"
                type="file"
                onChange={(e) => handleUpload('featured', e.target.files)}
                accept="video/mp4"
                style={{ display: 'none' }}
              />
            </Box>
          </Box>
          <Divider></Divider>
          <Box
            sx={{
              mt: 1,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'space-between',
              gap: '50px',
              height: '300px',
            }}
          >
            <Box
              sx={{
                mt: 1,
                width: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <label for="thumb">horizontal thumb</label>
              <img
                src={
                  hthumbs.filter(
                    (item) => item.species === species && item.gender === gender
                  )[0]?.img
                }
                alt=""
                height="300px"
                style={{ objectFit: 'scale-down' }}
              />
              <Box display="flex" flexDirection="row" gap="10px">
                <Button
                  variant="contained"
                  onClick={() =>
                    inputRefs.current[`h_thumb_${species}_${gender}`]?.click()
                  }
                >
                  upload
                </Button>
                {/* <Button variant="contained" onClick={handleDelete}>
                  delete
                </Button> */}
              </Box>
              <input
                ref={(el) =>
                  (inputRefs.current[`h_thumb_${species}_${gender}`] = el)
                }
                id="thumb"
                type="file"
                onChange={(e) => handleUpload('hthumb', e.target.files)}
                accept="image/webp"
                style={{ display: 'none' }}
              />
            </Box>
            <Box
              sx={{
                mt: 1,
                width: '600px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <label for="vertical">horizontal video</label>
              <VideoPlayer
                src={
                  horizontals.filter(
                    (item) => item.species === species && item.gender === gender
                  )[0]?.img
                }
              />
              <Box display="flex" flexDirection="row" gap="10px">
                <Button
                  variant="contained"
                  onClick={() =>
                    inputRefs.current[`h_video_${species}_${gender}`]?.click()
                  }
                >
                  upload
                </Button>
                {/* <Button variant="contained" onClick={handleDelete}>
                  delete
                </Button> */}
              </Box>
              <input
                ref={(el) =>
                  (inputRefs.current[`h_video_${species}_${gender}`] = el)
                }
                id="vertical"
                type="file"
                onChange={(e) => handleUpload('horizontal', e.target.files)}
                accept="video/mp4"
                style={{ display: 'none' }}
              />
            </Box>
          </Box>
          {/* {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 20,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            images.length > 0 && (
              <Gallery
                images={images}
                setImages={setImages}
                species={species}
                gender={gender}
                name={name}
              />
            )
          )} */}
        </Box>
      )}
    </>
  );
}

const VideoPlayer = ({ src, width = '100%', controls = true }) => {
  return (
    <video
      height="300px"
      style={{ top: 0, left: '150px', objectFit: 'contain' }}
      loop
      autoPlay
      controls={controls}
    >
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

function Gallery({ images, setImages, species, gender, name }) {
  const [deleteProductImage] = useDeleteProductVideoMutation();
  const handleDelete = (idx) => {
    var temp = [...images];
    temp.splice(idx, 1);
    setImages(temp);
    if (!images[idx].hasOwnProperty('file')) {
      deleteProductImage({
        name: name,
        type: images[idx].thumb ? 'thumb' : 'samples',
        filename: images[idx].uuid,
      });
    }
  };

  return (
    <>
      <ImageList
        sx={{
          width: '100%',
          height: 'auto',
          overflow: 'hidden',
          paddingTop: '50px',
        }}
        cols={4}
        rowHeight={300}
        gap={20}
      >
        {images.map((item, idx) => {
          if (item.species === species && item.gender === gender) {
            return (
              <ImageListItem key={idx} sx={{}}>
                <Box>
                  {item.thumb && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '10px',
                        left: '-20px',
                        backgroundColor: '#fecd27',
                        transform: 'rotate(-45deg)',
                        color: '#000',
                        padding: '0px 20px',
                      }}
                    >
                      THUMB
                    </div>
                  )}
                  {item.thumb ? (
                    <img src={item.img} alt="" height="300" key={idx} />
                  ) : (
                    <VideoPlayer src={item.img} />
                  )}
                  <ImageListItemBar
                    title={item.species}
                    subtitle={item.gender}
                    actionIcon={
                      <ButtonGroup>
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255)' }}
                          onClick={() => handleDelete(idx)}
                        >
                          <Delete />
                        </IconButton>
                      </ButtonGroup>
                    }
                  />
                </Box>
              </ImageListItem>
            );
          } else {
            return <></>;
          }
        })}
      </ImageList>
    </>
  );
}
