import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  ImageListItemBar,
  Typography,
} from '@mui/material';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import { useGetSingleOrderMutation } from 'api/apiSlice';
import ImageDialog from 'components/ImageDialog';
import AutoRefreshImage from 'components/AutoRefreshImage';
import { Download } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { formatDate } from 'utils';

const Order = () => {
  const navigate = useNavigate();
  const loadedPrompt = useSelector((state) => state.generate.loadedPrompt);
  const { orderId } = useParams();
  const location = useLocation();
  const [getSingleOrder, { isLoading }] = useGetSingleOrderMutation();
  const [promptName, setpromptName] = useState();
  const [createdAt, setcreatedAt] = useState();

  const [images, setimages] = useState([]);
  const [batch, setbatch] = useState();
  const [selectedImage, setselectedImage] = useState();
  const [open, setOpen] = useState(false);
  const [job, setjob] = useState('');

  const imageWidth = 240;
  useEffect(() => {
    document.title = orderId;
    getSingleOrder(orderId)
      .unwrap()
      .then((result) => {
        console.log(result.data);
        setimages(result.data.images);
        setbatch(result.data.batch_size + 1);
        setpromptName(result.data.product);
        setcreatedAt(formatDate(parseInt(result.data.finished_at * 1000)));
        setjob(Object.keys(result.data.jobs)[0]);
      });
  }, []);

  const handleOpenDialog = (index) => {
    setOpen(true);
    setselectedImage(index);
  };

  const handleSaveImage = (event, item) => {
    event.stopPropagation();
    fetch(item, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Credentials': true,
      },
      credentials: 'include',
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const urlx = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = urlx;
          const x = item.split('/').slice(-2);
          const filename = `${loadedPrompt.parent}_${loadedPrompt.name}.png`;
          link.setAttribute('download', filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadPrompt = () => {
    navigate(`/generate?order=${job}`);
  };

  return (
    <>
      <Box padding="50px" width="100%">
        <Box
          display="flex"
          justifyContent="center"
          alignContent="center"
          width="100%"
          gap="20px"
        >
          <Typography sx={{ fontSize: '28px' }}>
            {promptName} - {createdAt}
          </Typography>
          {/* <Button
            variant="contained"
            sx={{
              alignSelf: 'center',
              height: '30px',
            }}
            onClick={loadPrompt}
          >
            LOAD
          </Button> */}
        </Box>

        <ImageList
          cols={batch}
          rowHeight={150}
          gap="10px"
          sx={{ width: `${batch * imageWidth + batch * 20}px` }}
        >
          {images.map((item, index) => (
            <ImageListItem
              key={item}
              onClick={() => handleOpenDialog(index)}
              sx={{ cursor: 'pointer', width: `${240}px`, height: `${240}px` }}
              actionIcon={
                <IconButton>
                  <Download />
                </IconButton>
              }
            >
              <AutoRefreshImage
                src={item}
                alt={'file missing'}
                loading="lazy"
                width="230"
                height="230"
              />
              {item.includes('images') && (
                <ImageListItemBar
                  sx={{
                    background:
                      'linear-gradient(to top, rgba(0,0,0,0.9) 0%, ' +
                      'rgba(0,0,0,0.6) 80%, rgba(0,0,0,0.1) 100%)',

                    maxWidth: '230px',
                    marginBottom: '10px',
                  }}
                  title={item.title}
                  position="bottom"
                  actionIcon={
                    <>
                      <IconButton
                        sx={{
                          color: 'white',
                        }}
                        aria-label={`download`}
                        onClick={(event) => handleSaveImage(event, item)}
                      >
                        <Download />
                      </IconButton>
                    </>
                  }
                  actionPosition="right"
                />
              )}
            </ImageListItem>
          ))}
        </ImageList>
        <ImageDialog
          open={open}
          setOpen={setOpen}
          images={images}
          index={selectedImage}
        />
      </Box>
    </>
  );
};

export default Order;
