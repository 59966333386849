import { Box, Tooltip } from '@mui/material';
import { formatDate } from 'utils';
import { GridToolbar } from '@mui/x-data-grid';

const subscription = (data) => {
  return (
    <>
      <Box>
        <div>balance : {data.balance}</div>
        <div>type : {data?.type.split(':')[0]}</div>
        <div>expire at : {formatDate(data.expiration_time)}</div>
        <div>trial: {data?.period_type === 'TRIAL' ? 'YES' : 'NO'}</div>
      </Box>
    </>
  );
};

export const columns = [
  {
    field: '_id',
    headerName: '_id',
    width: 230,
    type: 'string',
  },
  {
    field: 'email',
    headerName: 'email',
    width: 320,
    type: 'string',
  },

  {
    field: 'created_at',
    headerName: 'created at',
    width: 150,
    editable: false,
  },

  {
    field: 'subscription',
    headerName: 'active',
    width: 80,
    editable: false,
    renderCell: (params) => (
      <Tooltip
        title={params.value.active ? subscription(params.value) : ''}
        arrow
      >
        {params.value.period_type === 'TRIAL' ? (
          <span className="cell-value" style={{ color: '#fecd27' }}>
            {params.value.active ? 'Yes' : 'No'}
          </span>
        ) : (
          <span className="cell-value">
            {params.value.active ? 'Yes' : 'No'}
          </span>
        )}
      </Tooltip>
    ),
    sortComparator: (v1, v2) => {
      if (v1.active < v2.active) return -1;
      if (v1.active > v2.active) return 1;
      return 0;
    },
  },
  {
    field: 'used_balance',
    headerName: 'total',
    width: 60,
    editable: false,
  },
];
