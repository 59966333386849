import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';

import { Progress, TextWrapCellRenderer } from 'components/SmallComponents';
import { columns } from './columns';
import ContextMenu from './ContextMenu';

import {
  useAddCreditsMutation,
  useGetUsersMutation,
  useActivateMutation,
  useMakeAdminMutation,
} from 'api/apiSlice';

import Toast from 'components/Toast';

function formatDate(inputString) {
  // Parse the input string into a Date object
  const dateObj = new Date(inputString);

  // Format the date components
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = dateObj.getFullYear();
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}

export default function DataGridUsers() {
  const apiRef = useGridApiRef();

  const [getUsers, { isLoading }] = useGetUsersMutation();
  const [addCredits, { isAddingCredits }] = useAddCreditsMutation();
  const [activate, { isActivating }] = useActivateMutation();
  const [makeAdmin, { isMakingAdmin }] = useMakeAdminMutation();

  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  const useCms = () => {
    return {
      changeAdminStatus: '',
    };
  };
  const { changeAdminStatus } = useCms();
  const [rows, setrows] = useState([]);

  useEffect(() => {
    async function gr() {
      const users = await getUsers().unwrap();
      console.log(users.users);
      const u = users.users.map((r, index) => {
        var temp = { ...r };
        temp.balance = r?.payments.balance;
        temp.id = index;
        temp.created_at = formatDate(r.date);
        if (Array.isArray(r.payments.receipts)) {
          temp.transactions = r.payments.receipts.length;
        } else {
          console.log(typeof r.payments.receipts);
          temp.transactions = 0;
        }
        if (r?.subscription) {
          console.log(r);
          temp.subscription = r.subscription;
          if (r.subscription?.used_balance) {
            temp.used_balance = r.subscription?.used_balance;
          } else {
            temp.used_balance = 0;
          }
        } else {
          temp.subscription = { active: false };
        }

        return temp;
      });
      setrows(u);
    }
    if (rows.length === 0) {
      gr();
    }
  }, []);

  const handleOnCellClick = (params) => {
    if (params.field === 'image') {
      window.open(params.value, '_blank', 'noreferrer');
    }
  };

  const [contextMenu, setContextMenu] = useState(null);
  const [rowClicked, setrowClicked] = useState(null);
  const handleRowContextMenu = (event) => {
    event.preventDefault();
    const rowId = Number(event.currentTarget.getAttribute('data-id'));
    setrowClicked(rowId);

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const copyPath = (generate) => {
    const record = apiRef.current.getRow(rowClicked);
    console.log(record);
    const path = `u:\\a0000009\\Gleem\\${generate ? 'Generation' : 'LORAs'}\\${
      record._id
    }`;
    navigator.clipboard.writeText(path);
  };

  const handleTopUp = async (value) => {
    console.log('topup');
    const r = apiRef.current.getRow(rowClicked);
    console.log(r);
    console.log(value);
    const resp = await addCredits({ email: r.email, amount: value }).unwrap();
    if (resp.success) {
      apiRef.current.updateRows([
        { id: rowClicked, balance: parseInt(r.balance) + value },
      ]);
      setAlert({
        show: true,
        severity: 'success',
        msg: `succesfully added credits for email ${r.email}.`,
      });
    } else {
      setAlert({
        show: true,
        severity: 'error',
        msg: 'Error adding credits',
      });
    }
  };

  const handleActivateAccount = async () => {
    const r = apiRef.current.getRow(rowClicked);
    const resp = await activate({ email: r.email }).unwrap();
    if (resp.success) {
      apiRef.current.updateRows([{ id: rowClicked, activated: true }]);
      setAlert({
        show: true,
        severity: 'success',
        msg: ` ${r.email} account activated`,
      });
    } else {
      setAlert({
        show: true,
        severity: 'error',
        msg: 'Error activating account',
      });
    }
  };

  const handleChangeAdminStatus = async () => {
    const r = apiRef.current.getRow(rowClicked);
    const resp = await makeAdmin({ email: r.email, value: !r.admin }).unwrap();
    if (resp.success) {
      apiRef.current.updateRows([{ id: rowClicked, admin: !r.admin }]);
      setAlert({
        show: true,
        severity: 'success',
        msg: ` ${r.email} account ${
          r.admin ? 'is no longer admin' : 'is now admin'
        } `,
      });
    } else {
      setAlert({
        show: true,
        severity: 'error',
        msg: 'Error c',
      });
    }
  };

  const test = () => {
    setAlert({
      show: true,
      severity: 'success',
      msg: ` test toast message `,
    });
  };
  return (
    <>
      <Toast alert={alert} setAlert={setAlert} />
      <Box sx={{ height: '90vh', width: '100%' }}>
        <DataGrid
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
            row: {
              onContextMenu: handleRowContextMenu,
              style: { cursor: 'context-menu' },
            },
            loadingOverlay: Progress,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              },
            },
            columns: {
              columnVisibilityModel: {
                index: false,
                job: false,
                order: false,
                generated_at: false,
              },
            },
          }}
          pageSizeOptions={[50]}
          onCellClick={handleOnCellClick}
          loading={isLoading}
          disableSelectionOnClick
          autoHeight={false}
        />
      </Box>
      <ContextMenu
        contextMenu={contextMenu}
        handleClose={handleClose}
        copyPath={copyPath}
        handleTopUp={handleTopUp}
        handleActivateAccount={handleActivateAccount}
        handleChangeAdminStatus={handleChangeAdminStatus}
        test={test}
      />
    </>
  );
}
