import { useState, useEffect, forwardRef, memo, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { CircularProgress, FormControl, useTheme } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import { Refresh } from '@mui/icons-material';

import SmallDialog from 'components/SmallDialog';
import PmsAccordion from 'components/PmsAccordian';
import Toast from 'components/Toast';
import PromptsTable2 from './PromptsTable2';
import CategoryMenu from '../Admin/CategoryMenu';
import GroupMenu from '../Admin/GroupMenu';
import Dropdown from 'components/Dropdown';

import { useCms } from 'utils/CMS';
import { useAuth } from 'utils/Auth';
import { speciesValues, genderValues, productionValues } from 'utils/data';
import CategoryManager from '../Admin/CategoryManager';
import DataGridPrommpts from './datagrid/DataGrid';
import DropdownChecks from 'scenes/generate/DropdownChecks';

import { useDispatch, useSelector } from 'react-redux';
import { promptListDialogOpen, setPromptListDialogOpen } from 'state/generate';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PromptsList = memo(function PromptsList({
  setPromptToLoad,
  appliedFilters,
  applyFilters,
}) {
  const theme = useTheme();
  const {
    addCategory,
    updateCategory,
    removeCategory,
    getTree,
    getPrompts,
    getAllCategories,
    getAllGroups,
  } = useCms();

  const isAdmin = useSelector((state) => state.authentication.isAdmin);

  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  const open = useSelector((state) => state.generate.promptListDialogOpen);
  const dispatch = useDispatch();

  const searchRef = useRef();
  const [searchValue, setsearchValue] = useState('');

  const [page, setpage] = useState(0);

  const [fetching, setfetching] = useState(true);
  const [loading, setloading] = useState(true);
  const [categoryName, setCategoryName] = useState('');

  const [createCategoryOpen, setCreateCategoryOpen] = useState(false);
  const [createCategoryData, setCreateCategoryData] = useState(false);
  const [tree, setTree] = useState({});
  const [prompts, setprompts] = useState([]);
  const [filteredprompts, setfilteredprompts] = useState([]);

  // Filter states
  const [selectedCategory, setselectedCategory] = useState([]);
  const [categories, setcategories] = useState([]);

  const [selectedProduct, setselectedProduct] = useState('All');
  const [products, setproducts] = useState([]);
  const [filteredroducts, setfilteredProducts] = useState([]);

  const [selectedSpecies, setselectedSpecies] = useState('All');
  const [selectedGender, setselectedGender] = useState('All');
  const [selectedStatus, setselectedStatus] = useState('All');

  // get prompts and categories on dialog open
  useEffect(() => {
    if (open) {
      setloading(true);
      getAllCategories().then((cats) => {
        setcategories(cats);

        // setselectedCategory([
        //   'Photo-unreal - with stuff like travels, weddings, history etc.',
        //   'Imagined - with stuff like fantasy, cartoons etc.',
        // ]);
        setselectedCategory(appliedFilters.category);
      });
      getAllGroups().then((groups) => {
        setproducts(['All', ...groups]);
        setfilteredProducts(['All', ...groups]);
        // setselectedProduct('All');
        setselectedProduct(appliedFilters.product);
      });
      getPrompts().then((items) => {
        if (items.success) {
          setprompts(items.msg.prompts);
          // setfilteredprompts(items.msg.prompts);
        }
      });
      setloading(false);
      // setselectedStatus('All')
      setselectedStatus(appliedFilters.status);
    }
  }, [open]);

  useEffect(() => {
    selectedCategory.length !== 0 &&
      setfilteredProducts([
        ...products.filter((product) => {
          var splitted = product.parent;
          if (product.parent !== 'test bin' && product !== 'All') {
            splitted = product.parent; //.split(' - ')[0];
          }
          return selectedCategory.includes(splitted);
        }),
      ]);
    prompts.length !== 0 && filterPrompts();
  }, [
    prompts,
    selectedCategory,
    selectedProduct,
    selectedSpecies,
    selectedGender,
    selectedStatus,
  ]);

  const refreshData = () => {
    getPrompts().then((items) => {
      if (items.success) {
        setprompts(items.msg.prompts);
        setfilteredprompts(items.msg.prompts);
      }
    });
    getAllCategories().then((cats) => {
      setcategories(cats);
      // setselectedCategory([
      //   'Photo-unreal - with stuff like travels, weddings, history etc.',
      //   'Imagined - with stuff like fantasy, cartoons etc.',
      // ]);
      setselectedCategory(appliedFilters.category);
    });
    getAllGroups().then((groups) => {
      setproducts(['All', ...groups]);
      setfilteredProducts(['All', ...groups]);
      // setselectedProduct('All');
      setselectedProduct(appliedFilters.product);
    });
    setselectedStatus(appliedFilters.status);
  };
  const filterPrompts = () => {
    setloading(true);
    var empty = { ...prompts[0] };
    Object.keys(empty).forEach((key) => (empty[key] = ''));

    const filter = {};
    selectedCategory !== 'All' &&
      Object.assign(filter, { category: selectedCategory });
    selectedProduct !== 'All' &&
      Object.assign(filter, { parent: selectedProduct });
    selectedSpecies !== 'All' &&
      Object.assign(filter, { species: selectedSpecies });
    selectedGender !== 'All' &&
      Object.assign(filter, { gender: selectedGender });
    selectedStatus !== 'All' &&
      Object.assign(filter, { isProduction: selectedStatus });

    console.log(`filter by :  ${Object.keys(filter)}`);
    console.log(filter);
    const fprompts = prompts.filter(function (item) {
      var result = true;
      for (var key in filter) {
        if (key !== 'category') {
          if (item[key] === undefined || item[key] !== filter[key]) {
            result = false;
          } else {
          }
        } else {
          if (item[key] === undefined || !filter[key].includes(item[key])) {
            result = false;
          } else {
          }
        }
      }
      return result;
    });
    console.log(fprompts.length);
    setfilteredprompts(fprompts.length !== 0 ? fprompts : [empty]);
  };
  useEffect(() => {
    setloading(false);
  }, [filteredprompts]);

  // useEffect(() => {
  //   if (searchValue.length > 2) {
  //     searchPrompt();
  //   }
  //   if (searchValue.length === 0) {
  //     searchPrompt();
  //   }
  // }, [searchValue]);

  const searchPrompt = () => {
    console.log(prompts.length);
    setpage(0);
    searchRef.current.value !== ''
      ? setfilteredprompts(
          prompts.filter((fprompt) => {
            return fprompt.name
              .toLowerCase()
              .includes(searchRef.current.value.toLowerCase());
          })
        )
      : filterPrompts();
  };

  useEffect(() => {
    Object.keys(tree).length !== 0 && setfetching(false);
  }, [tree]);

  // add category
  useEffect(() => {
    if (createCategoryData) {
      addCategory(categoryName).then((resp) => {
        if (resp.success) {
          getTree().then((items) => {
            setTree(items);
            setCreateCategoryData(false);
          });
        } else {
          setAlert({
            show: true,
            severity: 'error',
            msg: 'Category already exist',
          });
        }
      });
    }
  }, [createCategoryData]);

  const handleClose = () => {
    dispatch(setPromptListDialogOpen(false));
  };

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
  };

  const handleCategoryFilterChange = (value) => {
    setselectedCategory(value);
    applyFilters((current) => ({ ...current, category: value }));
  };

  const handleProductFilterChange = (value) => {
    setselectedProduct(value);
    applyFilters((current) => ({ ...current, product: value }));
  };

  const handleStatusFilterChange = (value) => {
    setselectedStatus(value);
    applyFilters((current) => ({ ...current, status: value }));
  };

  return (
    <div>
      <Toast alert={alert} setAlert={setAlert}></Toast>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: theme.palette.background.default, // Change background color here
          },
        }}
      >
        <AppBar
          sx={{
            position: 'relative',
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Prompts
            </Typography>

            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            mt: 1,
            width: '100vw',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '5px',
            padding: '10px',
          }}
        >
          {isAdmin && (
            <CategoryManager
              categories={categories}
              products={products}
              promptCount={prompts.length}
            />
          )}
          <IconButton
            color="inherit"
            onClick={refreshData}
            aria-label="refresh"
          >
            <Refresh />
          </IconButton>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => setCreateCategoryOpen(true)}
          >
            Add category
          </Button> */}
        </Box>
        <Box
          sx={{
            mt: 1,
            width: '100vw',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '5px',
            padding: '10px',
          }}
        >
          <FormControl sx={{ mt: '5px', width: '25ch' }} variant="outlined">
            <InputLabel
              htmlFor="search"
              sx={{ mt: '-7px', '&:focused': { mt: '0px' } }}
            >
              Search
            </InputLabel>

            <OutlinedInput
              id="search"
              type={'text'}
              inputRef={searchRef}
              size="small"
              sx={{}}
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      searchRef.current.value = '';
                      searchPrompt();
                    }}
                    aria-label="close"
                    sx={{ color: theme.palette.yellows[500] }}
                  >
                    <ClearIcon
                      sx={{ fontSize: '12px', marginRight: '-20px' }}
                    />
                  </IconButton>
                </InputAdornment>
              }
              // startAdornment={
              //   <InputAdornment position="start">
              //     <IconButton
              //       color="inherit"
              //       onClick={searchPrompt}
              //       aria-label="close"
              //       sx={{ color: '#ff6000', marginLeft: '-20px' }}
              //     >
              //       <SearchIcon />
              //     </IconButton>
              //   </InputAdornment>
              // }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  searchPrompt();
                }
              }}
              label="Search"
            />
          </FormControl>
          <IconButton
            color="inherit"
            onClick={searchPrompt}
            aria-label="close"
            sx={{ color: theme.palette.yellows[500] }}
          >
            <SearchIcon />
          </IconButton>
          <DropdownChecks
            name={'categories'}
            setValue={handleCategoryFilterChange}
            items={categories}
            width="340px"
          ></DropdownChecks>
          <Dropdown
            name={'products'}
            value={selectedProduct}
            setValue={handleProductFilterChange}
            items={[
              'All',
              ...filteredroducts
                .map((group) => {
                  return group.name;
                })
                .sort(),
            ]}
            width="240px"
          ></Dropdown>
          {/* <Dropdown
            name={'species'}
            value={selectedSpecies}
            setValue={setselectedSpecies}
            items={['All', ...speciesValues]}
            width="140px"
          ></Dropdown>
          <Dropdown
            name={'gender'}
            value={selectedGender}
            setValue={setselectedGender}
            items={['All', ...genderValues]}
            width="140px"
          ></Dropdown> */}
          <Dropdown
            name={'status'}
            value={selectedStatus}
            setValue={handleStatusFilterChange}
            items={['All', ...productionValues]}
            width="140px"
          ></Dropdown>
        </Box>
        <SmallDialog
          title="Add category"
          content=""
          open={createCategoryOpen}
          setOpen={setCreateCategoryOpen}
          setData={setCreateCategoryData}
          cancelButton="Cancel"
          confirmButton="Add"
          blocked={false}
        >
          <TextField
            margin="normal"
            id="promptName"
            label="name"
            name="name"
            value={categoryName}
            onChange={handleCategoryNameChange}
            className="ring-0"
            sx={{ width: '300px' }}
            size="small"
            onKeyDown={(e) => e.stopPropagation()}
          />
        </SmallDialog>
        {filteredprompts.length !== 0 &&
          (!loading ? (
            <PromptsTable2
              rows={filteredprompts}
              setPromptToLoad={setPromptToLoad}
              setTree={setTree}
              pagex={page}
              setpagex={setpage}
            />
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ))}
        {/* <DataGridPrommpts
          rows={filteredprompts}
          setPromptToLoad={setPromptToLoad}
          setTree={setTree}
          pagex={page}
          setpagex={setpage}
        /> */}
      </Dialog>
    </div>
  );
});

export default PromptsList;
