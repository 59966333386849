export const productsEndpoints = (builder) => {
  return {
    getProductImages: builder.mutation({
      query: (name) => ({
        url: `/productImages/${name}`,
        method: 'get',
      }),
    }),
    setProductImages: builder.mutation({
      query: (formData) => {
        const name = formData.get('name');
        return {
          url: `/productImages/${name}`,
          method: 'post',
          body: formData,
        };
      },
    }),
    deleteProductImage: builder.mutation({
      query: (data) => ({
        url: `/productImages/${data.name}?type=${data.type}&filename=${data.filename}`,
        method: 'delete',
      }),
    }),
    modifyImageType: builder.mutation({
      query: (data) => ({
        url: `/modifyThumb/${data.name}/${data.filename}?isThumb=${data.isThumb}`,
        method: 'post',
      }),
    }),
    getReleasedProducts: builder.mutation({
      query: () => ({
        url: `/api/v2/products`,
        method: 'get',
      }),
    }),
    modifyProductsOrder: builder.mutation({
      query: (data) => ({
        url: `/api/v2/productsOrder`,
        method: 'post',
        body: data,
      }),
    }),
    modifyFeaturedOrder: builder.mutation({
      query: (data) => ({
        url: `/api/v2/featuredOrder`,
        method: 'post',
        body: data,
      }),
    }),
    tempReleaseProduct: builder.mutation({
      query: (data) => ({
        url: `/api/v2/releaseProduct`,
        method: 'post',
        body: data,
      }),
    }),
    getProductType: builder.mutation({
      query: (id) => ({
        url: `/products/product/${id}/type`,
        method: 'get',
      }),
    }),
    updateProduct: builder.mutation({
      query: ({ id, group }) => ({
        url: `/products/product/${id}`,
        method: 'post',
        body: group,
      }),
    }),
    getProductVideos: builder.mutation({
      query: (name) => ({
        url: `/productVideos/${name}`,
        method: 'get',
      }),
    }),
    setProductVideos: builder.mutation({
      query: (formData) => {
        const name = formData.get('name');
        return {
          url: `/products/product/${name}/video`,
          method: 'post',
          body: formData,
        };
      },
    }),
    deleteProductVideo: builder.mutation({
      query: (data) => ({
        url: `/productVideos/${data.name}?type=${data.type}&filename=${data.filename}`,
        method: 'delete',
      }),
    }),
    getProduct: builder.mutation({
      query: (id) => ({
        url: `/products/product/${id}`,
        method: 'get',
      }),
    }),
    validateProduct: builder.mutation({
      query: (id) => ({
        url: `/products/product/validate/${id}`,
        method: 'get',
      }),
    }),
  };
};
