import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { Middleware } from 'redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import global from 'state/global';
import auth from 'state/auth';
import generate from 'state/generate';
import { apiSlice } from 'api/apiSlice';

import { AuthProvider } from 'utils/Auth';
import { CmsProvider } from 'utils/CMS';
import { GenerateProvider } from 'scenes/generate/Context';

const persistConfig = {
  key: 'root',
  storage,
  // Optionally, you can whitelist specific reducers to be persisted
  whitelist: ['authentication', 'global', 'generate'],
};

// const persistedReducer = persistReducer(
//   persistConfig,
//   combineReducers({
//     global,
//     authentication: auth,
//     generate: generate,
//     [apiSlice.reducerPath]: apiSlice.reducer,
//   })
// );

const rootReducer = combineReducers({
  global,
  authentication: auth,
  generate: generate,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const rootReducerWithReset = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = undefined; // Clear the state completely
  }
  return rootReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducerWithReset);

const redirectMiddleware = (store) => (next) => (action) => {
  if (action.type === 'RESET_STATE' || action.type === 'LOGOUT') {
    console.log('RESET');
    // window.location.href = '/login'; // Force redirect to login page
  }

  return next(action);
};

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          REGISTER,
          'RESET_STATE',
          'LOGOUT',
        ],
      },
    }).concat(redirectMiddleware, apiSlice.middleware),
});

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
        onBeforeLift={() => {
          const persistState = localStorage.getItem('persist:root');
          if (!persistState) {
            store.dispatch({ type: 'RESET_STATE' });
          }
        }}
      >
        <AuthProvider>
          <CmsProvider>
            <GenerateProvider>
              <App />
            </GenerateProvider>
          </CmsProvider>
        </AuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
