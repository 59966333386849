import { useState, useEffect, forwardRef } from 'react';

import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import CategoryManagerTable from './CategoryManagerTable';
import ProductsOrder from './ProductsOrder';
import FeaturedProducts from './FeaturedProducts';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CategoryManager = ({ categories, products, promptCount }) => {
  const [open, setopen] = useState(false);
  const [rows, setrows] = useState([]);

  const [currentTab, setcurrentTab] = useState(0);

  const handleChange = (event, newValue) => {
    setcurrentTab(newValue);
  };

  useEffect(() => {
    if (open) {
      setrows(createData(categories, products, promptCount));
    }
  }, [open]);

  function createData(categories, products, promptCount) {
    var tree = [];
    categories.map((category, index) => {
      var data = {};
      data.name = category;
      data.products = products.filter((product) => product.parent === category);
      data.productsCount = data.products.length;
      tree.push(data);
    });

    return tree;
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setopen(true)}>
        Settings
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setopen(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setopen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              ADMIN
            </Typography>
          </Toolbar>
        </AppBar>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Categories & products" {...a11yProps(0)} />
            <Tab label="Order" {...a11yProps(1)} />
            <Tab label="featured" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={currentTab} index={0}>
          <CategoryManagerTable rows={rows} />
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={1}>
          <ProductsOrder />
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={2}>
          <FeaturedProducts />
        </CustomTabPanel>
      </Dialog>
    </>
  );
};

export default CategoryManager;
