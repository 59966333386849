import { useEffect } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

export default function Pie({ data, size }) {
  return (
    <PieChart
      slotProps={{ legend: { hidden: true } }}
      series={[
        {
          arcLabel: (item) => `${item.value}%`,
          arcLabelMinAngle: 5,
          arcLabelRadius: '60%',
          data: data,
          highlightScope: { fade: 'global', highlight: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          cx: size.width / 2,
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fontWeight: 'regular',
          fontSize: '12px',
        },
      }}
      {...size}
    />
  );
}
