import React, { useState, useEffect } from 'react';
import { Container, Box, Paper, Typography, Button } from '@mui/material';

import {
  useGetReleasedProductsMutation,
  useModifyProductsOrderMutation,
} from 'api/apiSlice';
import DndList from 'components/DndList';
import Toast from 'components/Toast';

const initialItems = [
  { id: '1', text: 'Item 1' },
  { id: '2', text: 'Item 2' },
  { id: '3', text: 'Item 3' },
  { id: '4', text: 'Item 4' },
];

const ProductsOrder = () => {
  const [getProducts] = useGetReleasedProductsMutation();
  const [modifyProductsOrder] = useModifyProductsOrderMutation();

  const [items, setItems] = useState([]);

  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const products = await getProducts().unwrap();
    var temp = JSON.parse(JSON.stringify(products));
    temp.data.map((item, index) => (item.id = index));
    console.log(temp);
    setItems(temp.data);
  };

  const handleSaveOrder = async () => {
    const item_ids = items.map((item) => item._id);
    const res = await modifyProductsOrder({ order: item_ids }).unwrap();
    setAlert({
      show: true,
      severity: 'success',
      msg: 'Products order updated.',
    });
  };

  return (
    <>
      <Toast alert={alert} setAlert={setAlert}></Toast>
      <Box p={2} style={{ overflow: 'auto', minHeight: 'auto' }}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          height="40px"
          marginBottom="20px"
        >
          <Typography>Released products: {items.length}</Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#BBFF00',
              color: '#000',
              '&:hover': {
                backgroundColor: '#A5E000',
              },
              fontWeight: 700,
            }}
            onClick={handleSaveOrder}
          >
            Save
          </Button>
        </Box>
        <DndList items={items} setItems={setItems} featured={true} />
      </Box>
    </>
  );
};

export default ProductsOrder;
