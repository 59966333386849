export const promptsEndpoints = (builder) => {
  return {
    prompt: builder.mutation({
      query: (id) => ({
        url: `/prompt/${id}`,
        method: 'get',
      }),
    }),
    getPromptByName: builder.mutation({
      query: (data) => ({
        url: `getPromptByName`,
        method: 'post',
        body: data,
      }),
    }),
    createPrompt: builder.mutation({
      query: (data) => ({
        url: `createPrompt`,
        method: 'post',
        body: data,
      }),
    }),
    updatePrompt: builder.mutation({
      query: (data) => ({
        url: `updatePrompt`,
        method: 'post',
        body: data,
      }),
    }),
  };
};
