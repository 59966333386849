import React, { useState, useEffect } from 'react';
import { Container, Box, Paper, Typography, Button } from '@mui/material';

import {
  useGetReleasedProductsMutation,
  useModifyFeaturedOrderMutation,
  useModifyProductsOrderMutation,
} from 'api/apiSlice';
import DndList from 'components/DndList';
import Toast from 'components/Toast';

const FeaturedProducts = () => {
  const [getProducts] = useGetReleasedProductsMutation();
  const [modifyFeaturedOrder] = useModifyFeaturedOrderMutation();

  const [items, setItems] = useState([]);

  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const products = await getProducts().unwrap();
    var temp = JSON.parse(JSON.stringify(products));
    var t = temp.data
      .filter((item) => item.featured === true)
      .map((item, index) => ({ ...item, id: index }))
      .sort((a, b) => a.featured_order - b.featured_order);
    console.log(t);
    setItems(t);
  };

  const handleSaveOrder = async () => {
    const items_filtered = items.map((item, index) => {
      return { name: item.name, _id: item._id, order: index };
    });

    const res = await modifyFeaturedOrder({ order: items_filtered }).unwrap();
    setAlert({
      show: true,
      severity: 'success',
      msg: 'Featured products order updated.',
    });
  };

  return (
    <>
      <Toast alert={alert} setAlert={setAlert}></Toast>
      <Box p={2} style={{ overflow: 'auto', minHeight: 'auto' }}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          height="40px"
          marginBottom="20px"
        >
          <Typography>Featured products: {items.length}</Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#BBFF00',
              color: '#000',
              '&:hover': {
                backgroundColor: '#A5E000',
              },
              fontWeight: 700,
            }}
            onClick={handleSaveOrder}
          >
            Save
          </Button>
        </Box>
        <DndList items={items} setItems={setItems} featured={false} />
      </Box>
    </>
  );
};

export default FeaturedProducts;
