import { Paper, Typography } from '@mui/material';
import React from 'react';

const Block = ({ title, children, maxWidth }) => {
  return (
    <Paper
      variant="outlined"
      elevation={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        alignItems: 'center',
        border: '1px solid #1e1e1',
        paddingBottom: '20px',
        borderRadius: '10px',
        width: maxWidth,
      }}
    >
      <Typography
        sx={{
          alignSelf: 'center',
          paddingBottom: '20px',
          fontSize: '22px',
        }}
      >
        {title}
      </Typography>
      {children}
    </Paper>
  );
};

export default Block;
